import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { Link } from "react-scroll";
import "./footer.scss";

import Logo from '../../assets/footer/logo.svg';
import Logo2 from '../../assets/footer/dru_white.png';

const partnerBox = () => (
  <div className="footer">
      <Row>
        <Col xs={6} sm={6} md={6}>        
          <img src={Logo2} alt="logo" />
          {/* <p className="font10">© 2023 - dru</p> */}
        </Col>
        <Col xs={6} sm={6} md={6}>
          <div className="footer-box">
            <p className="font10">
              dru
            </p>
            <p className="font10">
              <span><a className="contact__social" href="mailto:contact@bydru.com">contact@bydru.com</a></span>
            </p>
          </div>
        </Col>
      </Row>
  </div>
);
export default partnerBox;
