import React from "react";

import "./title.scss";
import { Link } from "react-scroll";
import Arrow from '../../../assets/hero/arrow.svg';
import Logo2 from '../../../assets/hero/dru_black_full.png';
import { Row } from "react-bootstrap";

const title = (props) => (
  <>
      <Row className="contact-box">
        <Link to="footer" spy={true} smooth={true} offset={0} duration={500}>
          <div >
            <p>contact</p>
          </div>
        </Link>
      </Row>
    <div className="title__container">


      {/* <Row> */}
        <img src={Logo2} alt="logo" className="hero-image"/>
      {/* </Row> */}

      {/* <iframe src="https://player.vimeo.com/video/463925480?h=b071ef466a&amp;autoplay=1&amp;loop=1&amp;background=1&amp;app_id=122963" 
              frameborder="0" 
              allow="autoplay; fullscreen; picture-in-picture" 
              data-ready="true"
              className="iframe-container">
      </iframe> */}
        {/* <h2 className="weight300 font80 padding30">{props.title}</h2> */}
        
        {/* <h1>fine by us</h1> */}
        {/* <div className="desc">
          <p className="weight100">
            music.<br></br>sound.
          </p>
        </div>   */}
        {/* <Link to="hero" spy={true} smooth={true} offset={0} duration={500}>
          <div className="nav-button">
            <img src={Arrow} alt="arrow" />
          </div>
          
        </Link> */}
    </div>
  </>

);

export default title;
