import React from "react";
import "./modal.scss";

import ReactPlayer from "react-player"
import { Row, Col } from "react-flexbox-grid";


const successModal = (props) => (
  <div className="sucess__modal">
    <button onClick={props.closeModal} className='close-button'>x</button>
    <div className='player_wrapper'>
      <ReactPlayer
        url={props.link}
        controls
        width='80%'
        height='90%'
        />
    </div>
    <div className='modal_contents'>
      <Row>
        <Row>
          <Col xs={4} sm={4} md={1} lg={1}>
            Type
          </Col>
          <Col xs={8} sm={8} md={11} lg={11}>
            {props.tag}
          </Col>
        </Row>
        <Row>
          <Col xs={4} sm={4} md={1} lg={1}>
            Service
          </Col>
          <Col xs={8} sm={8} md={11} lg={11}>
            {
              props.service
                .map(item => <span>{item}</span>)
                .reduce((acc, x) => acc === null ? [x] : [acc, ' | ', x], null)
            }
          </Col>
        </Row>
        {
          props.client.length > 0 ? (
            <Row>
              <Col xs={4} sm={4} md={1} lg={1}>
                Client
              </Col>
              <Col xs={8} sm={8} md={11} lg={11}>
                {
                  props.client
                    .map(item => <span>{item}</span>)
                    .reduce((acc, x) => acc === null ? [x] : [acc, ' | ', x], null)
                }
              </Col>
            </Row>
          ) : null
        }
      </Row>
    </div>




    {/* <p>
    <b>Services</b>
    {
      props.service
        .map(item => <span>{item}</span>)
        .reduce((acc, x) => acc === null ? [x] : [acc, ' | ', x], null)
    }
    </p>
    <p><b>Client</b>
    {
      props.client
        .map(item => <span>{item}</span>)
        .reduce((acc, x) => acc === null ? [x] : [acc, ' | ', x], null)
    }
    </p> */}
  </div>
);

export default successModal;
