import React from "react";
import "./projectBox.scss";

const ProjectBox = (props) => {
  return (
    <>
      <div className="portfolio__box">
        <img src={props.preview} alt="project" />
        <div className="portfolio__hover-info">
          <div className="text-center">
            {/* <p className="fontPerc weight300">{props.title}</p> */}
            <p className="weight300">{props.title}</p>
            {/* <p className="font12 weight500">{props.tag}</p> */}
          </div>
        </div>
      </div>
    </>
  )
};

export default ProjectBox;
