import React from "react";
import { Row, Col } from "react-flexbox-grid";
import Masonry from "react-masonry-css";
//Scss
import "./portfolio.scss";
//Assets
import Preview1 from "../../assets/portfolio/project01/preview.png";
import Preview2 from "../../assets/portfolio/project02/preview.png";
import Preview3 from "../../assets/portfolio/project03/preview.png";
import Preview4 from "../../assets/portfolio/project04/preview.png";
import Preview5 from "../../assets/portfolio/project05/preview.png";
import Preview6 from "../../assets/portfolio/project06/preview.png";
import Preview7 from "../../assets/portfolio/project07/preview.png";
import Preview8 from "../../assets/portfolio/project08/preview.png";
import Preview9 from "../../assets/portfolio/project09/preview.png";
import Preview10 from "../../assets/portfolio/project10/preview.png";
import Preview11 from "../../assets/portfolio/project11/preview.png";
//Components
import ProjectBox from "../ui-components/projectBox/projectBox";
import Modal from '../modal/Modal';

const MUSIC = "music"
const SOUND_DESIGN = "sound design"
const FOLEY = "foley"
const VOICE = "voice over"
const FILM = "short film"

const BOOMBOX = "Boombox Sound"
const QUAMI = "xyz"
const CULT_NATION = "Cult Nation"
const POV = "POV"


const AD = "ad"
const MUSIC_VIDEO = "music video"
const DEMO = "demo"

class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // PORTFOLIO PROJECTS
      modalOpen: false,
      projects: [
        {
          id: "1",
          preview: Preview1,
          title: "Adidas | Human Race",
          tag: AD,
          service: [MUSIC],
          client: [BOOMBOX],
          link: "https://vimeo.com/477301420"
        },
        {
          id: "9",
          preview: Preview9,
          title: "Toronto Raptors",
          tag: AD,
          service: [MUSIC],
          client: [BOOMBOX],
          link: "https://www.youtube.com/watch?v=vHYjsrhCRS4&ab_channel=TorontoRaptors"
        },
        {
          id: "11",
          preview: Preview11,
          title: "Sapporo",
          tag: AD,
          service: [MUSIC],
          client: [BOOMBOX],
          link: "https://www.youtube.com/watch?v=fI6cREBDL8Q"
        },
        {
          id: "3",
          preview: Preview3,
          title: "Government of Ontario",
          tag: AD,
          service: [MUSIC],
        client: [BOOMBOX],
          link: "https://vimeo.com/677263045"
        },
        {
          id: "4",
          preview: Preview4,
          title: "selfless",
          tag: MUSIC_VIDEO,
          service: [SOUND_DESIGN, FOLEY],
          client: [QUAMI],
          link: "https://www.youtube.com/watch?v=j_YYcxlcYGg"
        },
        {
          id: "5",
          preview: Preview5,
          title: "Presto",
          tag: AD,
          service: [MUSIC],
          client: [BOOMBOX],
          link: "https://vimeo.com/717896583"
        },
        {
          id: "8",
          preview: Preview8,
          title: "Shedding",
          tag: FILM,
          service: [MUSIC],
          client: [POV],
          link: "https://vimeo.com/816732671"
        },
        {
          id: "10",
          preview: Preview10,
          title: "Northstar",
          tag: DEMO,
          service: [MUSIC],
          client: [],
          link: "https://vimeo.com/819545767"
        },
        {
          id: "7",
          preview: Preview7,
          title: "Westworld",
          tag: DEMO,
          service: [MUSIC],
          client: [],
          link: "https://www.youtube.com/watch?v=qxaLW6LHX9I"
        },
        {
          id: "6",
          preview: Preview6,
          title: "i know the sound",
          tag: MUSIC_VIDEO,
          service: [SOUND_DESIGN, FOLEY],
          client: [QUAMI],
          link: "https://www.youtube.com/watch?v=oIsJhDUbvUw"
        },
        
      ],
      // PORTFOLIO GALLERY WILL LOAD THIS AFTER FUNCTION "filterGallery" FINISH FILTERING
      filterResult: null,
      pickedFilter: "all",
      currentProject: null,
    };
  }

  // FIRST LOAD
  componentDidMount() {
    this.filterGallery("all");
  }

  //FILTER PORTFOLIO FUNCTION
  filterGallery = (target) => {
    let projectsArr = [...this.state.projects];
    let result;

    if (target !== "all") {
      result = projectsArr.filter((project) => project.tag === target);
    } else {
      result = projectsArr;
    }

    this.setState({ filterResult: result, pickedFilter: target, pickedFilterDropdown: "NEWEST" });
  };

  // FILTER DROP DOWN HOVER MENU FUNCTION
  filterMenuHover = (event) => {
    if(event) {
      this.setState({ filterMenuActive: true });
    }else {
      this.setState({ filterMenuActive: false });
    }
  }

  // OPEN MODAL
  openModal = (id) => {
    this.setState({currentProject : this.state.projects.find(project => project.id === id)});
    this.setState({ modalOpen: true});
    document.body.style.overflow = 'hidden';
  }
  // CLOSE MODAL
  closeModal = () => {
    this.setState({ modalOpen: false});
    document.body.style.overflow = 'unset';
  };

  // RENDER
  render() {
    let modalRender = null;
    if (this.state.modalOpen) {
      modalRender = <Modal closeModal={this.closeModal} link={this.state.currentProject.link} service={this.state.currentProject.service} client={this.state.currentProject.client} tag={this.state.currentProject.tag}/>;
    }

    let projectsRender = null;
    if (this.state.filterResult) {
      projectsRender = this.state.filterResult.map((project) => (
        <div onClick={() => this.openModal(project.id)}>
          <ProjectBox preview={project.preview} key={project.id} title={project.title} tag={project.tag} link={project.link} service={project.service} client={project.client} />
        </div>
      ));
    }
    // PORTFOLIO GALLERY BREAKPOINTS
    const portfolioBreakpoints = {
      default: 3,
      1300: 2,
      700: 1,
    };
    return (
      <div id="portfolio">
        {modalRender}
        <div className="wrapper">
        <p className="font20 padding40 weight100 desc">
              music.<br></br>sound.
              {/* we are an audio house specializing in forward thinking music, voice acting, sound design, and foley. */}
        </p>
          {/* <Row>
            <Col xs={12} sm={12} md={8} lg={9}>
              <div className="portfolio__nav">
                <ul>
                  <li className={this.state.pickedFilter === "all" ? "portfolio__nav-active font12" : "font12"} onClick={() => this.filterGallery("all")}>
                    ALL
                  </li>
                  <li className={this.state.pickedFilter === "AD" ? "portfolio__nav-active font12" : "font12"} onClick={() => this.filterGallery("AD")}>
                    AD
                  </li>
                  <li className={this.state.pickedFilter === "MUSIC VIDEO" ? "portfolio__nav-active font12" : "font12"} onClick={() => this.filterGallery("MUSIC VIDEO")}>
                    MUSIC VIDEO
                  </li>
                  <li className={this.state.pickedFilter === "TV & FILM" ? "portfolio__nav-active font12" : "font12"} onClick={() => this.filterGallery("TV & FILM")}>
                    TV & FILM
                  </li>
                  <li className={this.state.pickedFilter === "DEMOS" ? "portfolio__nav-active font12" : "font12"} onClick={() => this.filterGallery("DEMOS")}>
                    DEMOS
                  </li>
                </ul>
              </div>
            </Col>
          </Row> */}
          <Masonry breakpointCols={portfolioBreakpoints} className="my-masonry-grid" columnClassName="mint__gallery">
            {projectsRender}
          </Masonry>
        </div>
      </div>
    );
  }
}

export default Portfolio;


