import React from "react";
import Fade from "react-reveal/Fade";
import "./modal.scss";

import SuccessModal from "./SuccessModal";


const modal = (props) => {

  return (
    <div className="modal">
      <div className="backdrop"></div>
      {/* <div className="reveal_fade"> */}

        <Fade duration={500}>
          <SuccessModal closeModal={props.closeModal} link={props.link} service={props.service} client={props.client} tag={props.tag}/>
        </Fade>
      {/* </div>     */}

    </div>
  );
};

export default modal;
